.App {
  text-align: center;
}

.map {
  height: 1000px;
}

.center-calculators {
  margin: auto;
  width: 55%;
  padding: 10px;
}

.reactive-tabs{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.shalken{
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  padding: 0px;
  margin: 0px;

  height: 20%;
}

.header-left {
  margin-left: 20px;
  margin-bottom: 20px;
}

.table-margin {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.divider-spacer {
  margin-right: 30px;
  margin-left: 15px;
}

.banner {
  margin: auto !important;
  width: 45% !important;
}

.accuracy-margin {
  margin-left: 40px !important;
}

.item-dropdown {
  min-width: 300px !important;
}

.enemy-dropdown {
  min-width: 300px !important;
}

.ui.secondary.pointing.menu .active.item {
  border: none;
}

.value {
  font-size: 40px;
}